import { Button } from '@mui/material';
import React from 'react';

const SentInviteActions = ({ inviteItem, inviteStatus, changeInviteStatus, invitePract }) => {
  return (
    <>
      {inviteItem.status === inviteStatus.ACCEPTED ? (
        <Button
          onClick={() => changeInviteStatus(inviteStatus.REMOVED)}
          fullWidth
          color="primary"
          variant="contained">
          Remove practitioner
        </Button>
      ) : null}
      {inviteItem.status === inviteStatus.EXPIRED ? (
        <Button
          onClick={invitePract}
          fullWidth
          color="primary"
          variant="contained"
          // disabled={!seatsAvailable}
        >
          Invite again
        </Button>
      ) : null}
      {inviteItem.status === inviteStatus.INVITED ? (
        <div>
          <Button
            // disabled
            onClick={() => changeInviteStatus(inviteStatus.WITHDRAWN)}
            type="text"
            fullWidth
            color="primary">
            withdraw invitation
          </Button>
        </div>
      ) : null}
    </>
  );
};

export default SentInviteActions;
