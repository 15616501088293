import React from 'react';
import ArrayInput from '../../../shared/components/array-input/ArrayInput';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { useFieldArray } from 'react-hook-form';

const Specialities = ({ control, setProfileChanged }) => {
  const {
    fields: specialities,
    append: addSpecialities,
    remove: removeSpecialities
  } = useFieldArray({
    control,
    name: 'specialities'
  });

  const handleAddSpeciality = (value) => {
    addSpecialities(value);
    setProfileChanged(true);
  };

  const handleRemoveSpeciality = (index) => {
    removeSpecialities(index);
    setProfileChanged(true);
  };

  return (
    <>
      <div className="heading-div">
        <b>Specialities</b>
      </div>
      <div className="form-row flex-container">
        <div className="field margin-bottom--xs margin-top--md">
          <ArrayInput
            name="Speciality"
            addInput={handleAddSpeciality}
            removeInput={handleRemoveSpeciality}
            fields={specialities}
            placeholder="Depression, Anxiety, ADHD, OCD, Parenting..."
            Icon={StarBorderIcon}
          />
        </div>
      </div>
      <style jsx>{`
        .heading-div {
          border-bottom: 1px solid var(--border-gray);
          padding: 5px 5px 15px 5px;
          margin-top: 10px;
          font-size: 18px;
        }

        .form-row {
          justify-content: space-between;
          flex-wrap: wrap;
        }
        .field {
          width: 49%;
          min-width: 300px;
          position: relative;
        }

        @media only screen and (max-width: 600px) {
          .field {
            width: 100%;
          }
        }
      `}</style>
    </>
  );
};

export default Specialities;
