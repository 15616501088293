import { format } from 'date-fns';
import React from 'react';
import { statusEnum } from '../../../../../helper/constants.helper';

const BookingTimeline = ({ schedule, day }) => {
  const timeToMinutes = (time, isEndTime = false) => {
    const [timeString, modifier] = time.split(' ');
    let [hours, minutes] = timeString.split(':').map(Number);
    if (modifier === 'PM' && hours !== 12) {
      hours += 12;
    } else if (modifier === 'AM' && hours === 12) {
      hours = 0;
    }
    if (isEndTime && modifier === 'AM' && hours === 0 && minutes === 0) {
      return 1440;
    }
    return hours * 60 + minutes;
  };

  const calculateWidthPercentage = (startTime, endTime) => {
    const startMinutes = timeToMinutes(format(new Date(startTime), 'hh:mm a'));
    const endMinutes = timeToMinutes(format(new Date(endTime), 'hh:mm a'), true);
    const durationInMinutes = endMinutes - startMinutes;
    return (durationInMinutes / 1440) * 100;
  };

  const calculateLeftPercentage = (startTime) => {
    const startMinutes = timeToMinutes(format(new Date(startTime), 'hh:mm a'));
    return (startMinutes / 1440) * 100;
  };
  return (
    <>
      <div className="timeline-container margin-top--sm">
        {schedule[day]
          ?.filter(
            (booking) =>
              booking.status === statusEnum.COMPLETED || booking.status === statusEnum.INITIATED
          )
          .map((booking, i) => (
            <div
              key={i}
              className="booking-slot"
              style={{
                left: `${calculateLeftPercentage(booking.meetingStart)}%`,
                width: `${calculateWidthPercentage(booking.meetingStart, booking.meetingEnd)}%`
              }}></div>
          ))}
      </div>
      <hr
        style={{
          margin: '0px',
          marginTop: '-5.4px',
          zIndex: 9,
          border: 'none',
          height: '1px',
          backgroundImage: 'radial-gradient(circle, var(--border-gray) 1px, transparent 1px)',
          backgroundSize: '6px 1px',
          backgroundRepeat: 'repeat-x'
        }}
      />
      <style jsx>{`
        .timeline-container {
          position: relative;
          width: 100%;
          height: 10px;
        }
        .booking-slot {
          position: absolute;
          height: 10px;
          background-color: #007aff;
          border-radius: 5px;
        }
      `}</style>
    </>
  );
};

export default BookingTimeline;
