import React, { useEffect, useState } from 'react';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import { Document, Page, pdfjs } from 'react-pdf';
import { Button, TextField, IconButton } from '@mui/material';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';

// @ts-expect-error This does not exist outside of polyfill which this is doing
if (typeof Promise.withResolvers === 'undefined') {
  if (window)
    // @ts-expect-error This does not exist outside of polyfill which this is doing
    window.Promise.withResolvers = function () {
      let resolve, reject;
      const promise = new Promise((res, rej) => {
        resolve = res;
        reject = rej;
      });
      return { promise, resolve, reject };
    };
}
pdfjs.GlobalWorkerOptions.workerSrc =
  'https://unpkg.com/pdfjs-dist@4.4.168/legacy/build/pdf.worker.min.mjs';

const DisplayPdf = ({ item }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [scale, setScale] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  const updateScale = () => {
    const width = window.innerWidth;
    if (width <= 400) {
      setScale(0.4);
    } else if (width <= 470) {
      setScale(0.5);
    } else if (width <= 550) {
      setScale(0.6);
    } else if (width <= 630) {
      setScale(0.75);
    } else if (width <= 680) {
      setScale(0.85);
    } else if (width <= 750) {
      setScale(0.95);
    } else if (width <= 820) {
      setScale(1.05);
    } else {
      setScale(1.15);
    }
  };

  useEffect(() => {
    updateScale();
    window.addEventListener('resize', updateScale);

    return () => {
      window.removeEventListener('resize', updateScale);
    };
  }, [scale]);

  return (
    <div className="pdf-viewer  flex-container direction-column align-center">
      <Document file={item.fileUrl} onLoadSuccess={onDocumentLoadSuccess}>
        <Page pageNumber={pageNumber} className="pdf-page" scale={scale} />
      </Document>
      <div style={{ textAlign: 'center' }}>
        <IconButton disabled={pageNumber <= 1} onClick={previousPage} color="primary">
          <ArrowBack />
        </IconButton>
        <span>
          Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
        </span>
        <IconButton disabled={pageNumber >= numPages} onClick={nextPage} color="primary">
          <ArrowForward />
        </IconButton>
      </div>
      <style jsx>
        {`
          .pdf-viewer {
            border: 1px solid lightgrey;
            padding: 10px 0px;
            max-height: 200px;
            width: 100%;
            height: auto;
            overflow: scroll;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
            max-width: 900px;
          }
          .pdf-page {
            width: 100%;
            height: auto;
          }

          span {
            margin: 0px 10px;
          }
        `}
      </style>
    </div>
  );
};

export default DisplayPdf;
