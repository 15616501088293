import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@mui/material';
import React, { useRef } from 'react';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import EventIcon from '@mui/icons-material/Event';
import { getCurrentDateTime } from '../shared/clientNotes.service';

const UpdateMeetingDetails = ({
  updatedMeetingDetails,
  handleMeetingDetailsChange,
  signed,
  locations
}) => {
  const inputRef = useRef(null);
  const handleIconClick = () => {
    if (inputRef.current) {
      inputRef.current.showPicker();
    }
  };
  return (
    <>
      <div>
        <div className="margin-top--sm">
          <TextField
            name="startTime"
            size="small"
            label="Start Time"
            type="datetime-local"
            value={getCurrentDateTime(updatedMeetingDetails.startTime)}
            onChange={handleMeetingDetailsChange}
            InputLabelProps={{
              shrink: true
            }}
            inputRef={inputRef}
            fullWidth
            disabled={signed}
            style={{ maxWidth: 300 }}
            sx={{
              "& input[type='datetime-local']::-webkit-calendar-picker-indicator": {
                display: 'none'
              }
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    size="small"
                    onClick={handleIconClick}
                    style={{ marginRight: '-9px' }}
                    disabled={signed}>
                    <EventIcon style={{ fontSize: '20px' }} color="primary" />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </div>
        <div>
          <TextField
            size="small"
            label="Duration (min)"
            type="number"
            name="duration"
            onChange={handleMeetingDetailsChange}
            value={updatedMeetingDetails.duration}
            // fullWidth
            inputProps={{ min: 0 }}
            disabled={signed}
            style={{
              marginTop: '20px',
              maxWidth: '300px'
            }}
            fullWidth
          />
        </div>
        <div className="location-container flex-container ">
          <FormControl
            style={{
              maxWidth: 300,
              width: '100%',
              marginRight: '10px',
              marginTop: '20px'
            }}>
            <InputLabel id="location-label">Location</InputLabel>
            <Select
              name="location"
              labelId="location-label"
              size="small"
              label="Location"
              disabled={signed}
              value={updatedMeetingDetails.location}
              fullWidth
              onChange={handleMeetingDetailsChange}
              startAdornment={
                <InputAdornment position="start">
                  <PlaceOutlinedIcon style={{ color: signed ? 'GrayText' : 'black' }} />
                </InputAdornment>
              }>
              {locations?.map((location) => (
                <MenuItem key={location.id} value={location.id}>
                  {location.location}
                </MenuItem>
              ))}
              <MenuItem value="other">Other</MenuItem>
            </Select>
          </FormControl>

          {updatedMeetingDetails?.location === 'other' && (
            <TextField
              size="small"
              name="otherLocation"
              label="Other Location"
              placeholder="Enter address here..."
              disabled={signed}
              value={updatedMeetingDetails?.otherLocation}
              fullWidth
              onChange={handleMeetingDetailsChange}
              style={{
                maxWidth: '300px',
                marginTop: '20px'
              }}
            />
          )}
        </div>
      </div>
      <style jsx>
        {`
          .location-container {
            width: 100%;
          }
          @media only screen and (max-width: 980px) {
            .location-container {
              flex-direction: column;
            }
          }
        `}
      </style>
    </>
  );
};

export default UpdateMeetingDetails;
