/* eslint-disable no-undef */
import { Fab } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import toast from 'react-hot-toast';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Style, Domain } from '@mui/icons-material';
import VideocamIcon from '@mui/icons-material/Videocam';
import PsychologyIcon from '@mui/icons-material/Psychology';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import Step from './Step';
import { getDomain, getProgressDataFromDb } from './overview.service';
import ProgressBar from '../../../../shared/components/progressbar/ProgressBar';
import { useStoreState } from 'easy-peasy';
import WorkIcon from '@mui/icons-material/Work';

const Overview = () => {
  const [progressData, setProgressData] = useState({});
  const [incompleteMandatorySteps, setIncompleteMandatorySteps] = useState(0);
  const [subDomain, setSubDomain] = useState();
  const [notOptionalSetup, setNotOptionalSetup] = useState(true);
  const practitionerData = useStoreState((state) => state.practitionerData);
  const practitioner = useStoreState((state) => state.user);

  const getSiteDomain = async () => {
    const domain = await getDomain(practitionerData.own_organisation).then((res) => res.siteDomain);
    if (domain && domain !== undefined && domain !== null) setSubDomain(domain);
  };

  const getProgressData = () => {
    getProgressDataFromDb(practitioner?.uid)
      .then((resData) => {
        if (resData?.domain) {
          getSiteDomain();
        }
        if (resData.availability && resData.services) {
          setNotOptionalSetup(false);
        }
        let stepsCompleted = 0;
        Object.keys(resData).forEach((key) => {
          if (resData[key]) {
            stepsCompleted++;
          }
        });
        const data = {
          stepsCompleted,
          steps: [
            {
              icon: <AccountCircleIcon />,
              title: 'Profile',
              isMandatory: true,
              isComplete: resData.profile,
              link: '/dashboard/profile'
            },
            {
              icon: <EventAvailableIcon />,
              title: 'Availability',
              isMandatory: false,
              isComplete: resData.availability,
              link: '/dashboard/availability'
            },
            {
              icon: <PsychologyIcon />,
              title: 'Services',
              isMandatory: false,
              isComplete: resData.services,
              link: '/dashboard/services'
            },
            {
              icon: <Domain />,
              title: 'My Practice/Domain',
              isMandatory: true,
              isComplete: resData.domain,
              link: '/dashboard/practice/domain-branding'
            },
            {
              icon: <Style />,
              title: 'My Practice/Branding',
              isMandatory: false,
              isComplete: resData.branding,
              link: '/dashboard/practice/domain-branding'
            }
          ]
        };
        setProgressData(data);
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    if (practitioner && practitionerData.own_organisation) {
      getProgressData();
    }
  }, [practitioner, practitionerData]);

  useEffect(() => {
    setIncompleteMandatorySteps(
      progressData?.steps?.some((step) => step.isMandatory && !step.isComplete)
    );
  }, [progressData]);

  return (
    <div className="container">
      <p className="text--md margin-bottom--xxs">
        {incompleteMandatorySteps ? (
          `Your website is NOT live. Complete the required setup to make it accessible to clients.`
        ) : notOptionalSetup ? (
          <p>
            Your website is live at &nbsp;
            <a
              className="link"
              href={`https://${subDomain}.detalks.com`}
              target="_blank"
              rel="noreferrer">
              https://{subDomain}.detalks.com
            </a>
            , but clients cannot schedule bookings as no availability or services are set.
          </p>
        ) : (
          <p>
            Your website is live. Share it with your clients to schedule bookings at &nbsp;
            <a
              className="link"
              href={`https://${subDomain}.detalks.com`}
              target="_blank"
              rel="noreferrer">
              https://{subDomain}.detalks.com
            </a>
          </p>
        )}
      </p>
      {incompleteMandatorySteps || notOptionalSetup ? (
        <>
          <ProgressBar total={5} current={progressData.stepsCompleted} />
          <div className="steps-container">
            <Step steps={progressData?.steps} />
          </div>
        </>
      ) : null}
      <style jsx>{`
        .container {
          padding: 10px;
        }
        .link {
          text-decoration: underline;
          color: var(--primary-color);
        }
      `}</style>
    </div>
  );
};

export default Overview;
