import { Hidden } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ReceivedInvitationsHistory from './received-invitations-history/ReceivedInvitationsHistory';
import { Helmet } from 'react-helmet';
import { useStoreState } from 'easy-peasy';
import { collection, getDocs, onSnapshot, orderBy, query, where } from 'firebase/firestore';
import { database } from '../../helper/firebase.helper';
import InviteHistoryItem from '../my-practice/invites/inviteHistory/invite-history-item/InviteHistoryItem';
import Loader from '../../shared/components/loader/Loader';

const OtherPractices = () => {
  const [inviteHistory, setInviteHistory] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const practitioner = useStoreState((state) => state.user);

  useEffect(() => {
    setLoading(true);
    const unsubscribe = onSnapshot(
      query(
        collection(database, 'invites'),
        where('email', '==', practitioner.email),
        orderBy('updateTime', 'desc')
      ),
      (docs) => {
        const res = [];
        docs.forEach((doc) => res.push({ id: doc.id, ...doc.data() }));
        setLoading(false);
        setInviteHistory(res);
        setLoading(false);
        return res;
      }
    );
    return () => {
      unsubscribe();
    };
  }, []);
  return (
    <div style={{ padding: 'var(--space-unit)' }} className="wrapper margin-top--sm">
      {isLoading ? <Loader /> : null}

      <Helmet>
        <title>Other Practices - DeTalks</title>
      </Helmet>
      <h4 className="margin-top--xxxs margin-bottom--md">Received Invitations</h4>

      {inviteHistory && inviteHistory.length ? (
        <>
          <div className="flex-container header">
            <p className="item text--sm gray-text">Practitioner Email</p>
            <Hidden smDown>
              <p className="item text--sm gray-text">Practitioner Name</p>
            </Hidden>
            <Hidden smDown>
              <p className="item text--sm gray-text">Status</p>
            </Hidden>
            <div className="item text--sm gray-text flex-container actions">Actions</div>
          </div>
          <hr />
          <div>
            {/* <ReceivedInvitationsHistory /> */}
            {inviteHistory?.map((inviteItem) => (
              <InviteHistoryItem
                key={inviteItem.id}
                inviteItem={inviteItem}
                practitioner={practitioner}
                // isInGroupPractice={!isAdmin}
                // seatsAvailable={currPackage?.seats > currPackage?.group_seats_used}
                // usedSeats={currPackage?.group_seats_used}
              />
            ))}
          </div>
        </>
      ) : (
        <p>No invitations received yet!</p>
      )}

      <style jsx>{`
        .header {
        }
        .item {
          width: 25%;
          flex-grow: 1;
        }
        hr {
          border: none;
          background-color: var(--gray-lightest);
          height: 1px;
        }
        .actions {
          justify-content: space-between;
          align-items: center;
        }
      `}</style>
    </div>
  );
};

export default OtherPractices;
