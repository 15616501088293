import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import { collection, getDocs } from 'firebase/firestore';
import { useStoreState } from 'easy-peasy';

import psypackicon from '../../../assets/psypackicon.png';
import googlemeet from '../../../assets/googlemeet.png';
import googlecalendar from '../../../assets/googlecalendar.png';
import PsypackIntegration from './PsypackIntegration';
import { database } from '../../../helper/firebase.helper';
import toast from 'react-hot-toast';
import { removePsypackIntegration } from '../../../helper/api.helper';

const MyIntegrations = () => {
  const [integrations, setIntegrations] = useState([]);
  const [psypackOpen, setPsypackOpen] = useState(false);
  const practitionerData = useStoreState((state) => state.practitionerData);

  useEffect(() => {
    if (practitionerData && practitionerData.own_organisation) {
      getDocs(
        collection(database, 'organisations', practitionerData?.own_organisation, 'integrations')
      ).then((docs) => {
        const data = [];
        docs.forEach((doc) => data.push(doc.id));
        setIntegrations(data);
      });
    }
  }, [practitionerData]);

  const removePsyPack = () => {
    toast.promise(
      removePsypackIntegration()
        .then((res) => res.json())
        .then((res) => {
          if (res.status === 'fail') {
            throw Error(res.message);
          } else {
            setIntegrations((integrations) =>
              integrations.filter((section) => section !== 'psypack')
            );
          }
        }),
      {
        loading: 'Removing integration',
        success: 'PsyPack removed',
        error: (err) => err.message
      }
    );
  };

  return (
    <div className="integrations-container flex-container ">
      <div className="integration-item margin-top--sm">
        <img src={psypackicon} alt="PsyPAck Icon" />
        <h4 className="margin-top--xs">PsyPack Assessments</h4>
        <p className="margin-bottom--md">
          Integrate PsyPack to administer assessments, track progress and access detailed reports
          directly within DeTalks. Streamline your evaluation process effortlessly.
        </p>

        {integrations?.includes('psypack') ? (
          <div className="flex-container action-buttons">
            <p style={{ color: '#007aff' }}>Connected</p>
            <Button size="small" color="error" variant="outlined" onClick={removePsyPack}>
              Disconnect
            </Button>
          </div>
        ) : (
          <Button size="small" variant="contained" onClick={() => setPsypackOpen(true)}>
            Connect
          </Button>
        )}
        <PsypackIntegration
          setIntegrations={setIntegrations}
          open={psypackOpen}
          handleOpen={setPsypackOpen}
        />
      </div>
      <div className="integration-item margin-top--sm">
        <img src={googlemeet} alt="Google Meet Icon" />

        <h4 className="margin-top--xs">Google Meet</h4>
        <p>
          Connect your account to schedule and host secure video sessions directly from DeTalks.
          Simplify virtual therapy with seamless integration.
        </p>
        <p style={{ color: '#007aff' }}>Coming soon</p>
      </div>
      <div className="integration-item margin-top--sm">
        <img src={googlecalendar} alt="Google Meet Icon" />

        <h4 className="margin-top--xs">Google Calendar</h4>
        <p>
          Seamlessly sync your appointments and manage your schedule effortlessly. Connect your
          account to ensure all your sessions are organized in one place.
        </p>
        <p style={{ color: '#007aff' }}>Coming soon</p>
      </div>

      <style jsx>{`
        .integrations-container {
          max-width: 1000px;
          flex-wrap: wrap;
        }
        .integration-item {
          max-width: 30%;
          margin-left: 10px;
          min-width: 200px;
          //   flex-basis: 30%;
          border-radius: 10px;
          border: 1px solid var(--border-gray);
          padding: 10px;
        }
        .integration-item img {
          width: 70px;
        }
        .action-buttons {
          justify-content: space-between;
        }
      `}</style>
    </div>
  );
};

export default MyIntegrations;
