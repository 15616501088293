import React from 'react';
import { useFieldArray } from 'react-hook-form';
import InterestsOutlinedIcon from '@mui/icons-material/InterestsOutlined';
import ArrayInput from '../../../shared/components/array-input/ArrayInput';

const Modalities = ({ control, setProfileChanged }) => {
  const {
    fields: modalities,
    append: addModalities,
    remove: removeModalities
  } = useFieldArray({
    control,
    name: 'modalities'
  });

  const handleAddModalities = (value) => {
    addModalities(value);
    setProfileChanged(true);
  };

  const handleRemoveModalities = (index) => {
    removeModalities(index);
    setProfileChanged(true);
  };

  return (
    <>
      <div className="heading-div">
        <b>Modalities</b>
      </div>
      <div className="form-row flex-container">
        <div className="field margin-bottom--xs margin-top--md">
          <ArrayInput
            name="Modality"
            addInput={handleAddModalities}
            removeInput={handleRemoveModalities}
            fields={modalities}
            placeholder="Cognitive Behavioral Therapy, Trauma Informed Care..."
            Icon={InterestsOutlinedIcon}
          />
        </div>
      </div>
      <style jsx>{`
        .heading-div {
          border-bottom: 1px solid var(--border-gray);
          padding: 5px 5px 15px 5px;
          margin-top: 10px;
          font-size: 18px;
        }

        .form-row {
          justify-content: space-between;
          flex-wrap: wrap;
        }
        .field {
          width: 49%;
          min-width: 300px;
          position: relative;
        }

        @media only screen and (max-width: 600px) {
          .field {
            width: 100%;
          }
        }
      `}</style>
    </>
  );
};

export default Modalities;
