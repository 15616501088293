import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { getIdTokenResult } from 'firebase/auth';
import { useStoreActions } from 'easy-peasy';
import { auth, database } from '../../helper/firebase.helper';
import { doc, getDoc } from 'firebase/firestore';

const VerifyEmail = () => {
  const navigate = useNavigate();
  const setUser = useStoreActions((actions) => actions.setUser);
  const setPractitionerData = useStoreActions((actions) => actions.setPractitionerData);

  const getPractitionerData = (practitionerId) => {
    getDoc(doc(database, 'practitioners', practitionerId)).then((doc) => {
      setPractitionerData({
        id: doc.id,
        ...doc.data()
      });
    });
  };

  useEffect(() => {
    const timer = setInterval(() => {
      if (
        auth.currentUser?.emailVerified
        //  &&
        // searchParams.get("email") === auth.currentUser?.email
      ) {
        const password = sessionStorage.getItem(auth.currentUser.email);
        auth.signInWithEmailAndPassword(auth.currentUser.email, password).then(() => {
          getIdTokenResult(auth.currentUser, true).then((res) => {
            if (res.claims?.userType === 1) {
              setUser({
                uid: auth.currentUser.uid,
                email: auth.currentUser.email,
                emailVerified: auth.currentUser.emailVerified,
                displayName: auth.currentUser.displayName,
                orgId: res.claims.orgId,
                userType: res.claims.userType
              });
              getPractitionerData(auth.currentUser.uid);
            } else {
              setUser(null);
            }
          });
          toast.success('Email registered successfully!');
          navigate('/register/register-number');
          sessionStorage.removeItem(auth.currentUser.email);
        });
      } else {
        auth.currentUser?.reload();
      }
    }, 3000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div className="verify">
      <h4>You&apos;re almost there</h4>
      <p>
        Thanks for signing up! You would see a verification link in your email (it might be in your
        spam folder). Verify your email to access DeTalks.
      </p>
      <style jsx>{`
        .verify {
          text-align: center;
          padding: 5% 10% 10% 10%;
          min-height: 80vh;
        }
      `}</style>
    </div>
  );
};

export default VerifyEmail;
