import {
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  MenuItem,
  Select
} from '@mui/material';
import React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import toast from 'react-hot-toast';

const AssessmentItem = ({
  index,
  assessment,
  selfAssessments,
  setSelfAssessments,
  allAssessments,
  setAssessmentsChanged
}) => {
  const handleDeleteAssessment = (index) => {
    setSelfAssessments((prevAssessments) => prevAssessments.filter((_, i) => i !== index));
    setAssessmentsChanged(true);
  };

  const handleAssessmentSelect = async (index, event) => {
    const selectedAssessment = await allAssessments?.find(
      (assessment) => assessment.id === event.target.value
    );
    if (!selectedAssessment) {
      toast.error('Selected assessment not found!');
      return;
    }
    const assessmentAlreadyExists = await selfAssessments?.some(
      (assessment) => assessment.id && assessment.id === selectedAssessment.id
    );
    if (assessmentAlreadyExists) {
      toast.error('Assessment already added!');
      return;
    }
    const updatedAssessments = [...selfAssessments];
    updatedAssessments[index] = {
      ...updatedAssessments[index],
      id: selectedAssessment.id
    };
    setSelfAssessments(updatedAssessments);
  };

  const handleShareReportChange = (index) => {
    setSelfAssessments((prevAssessments) => {
      const updatedAssessments = [...prevAssessments];
      updatedAssessments[index].share_report = !updatedAssessments[index].share_report;
      return updatedAssessments;
    });
    setAssessmentsChanged(true);
  };

  return (
    <div>
      <div
        className="flex-container justify-between "
        style={{
          // maxWidth: "800px",
          borderBottom: '1px solid var(--border-gray)'
        }}>
        <h4 className="margin-top--xxs">Assessment {index + 1}</h4>
        {
          <IconButton
            aria-label="delete"
            color="secondary"
            onClick={() => handleDeleteAssessment(index)}>
            <DeleteIcon />
          </IconButton>
        }
      </div>
      <Select
        required
        size="small"
        value={assessment.id}
        onChange={(event) => handleAssessmentSelect(index, event)}
        fullWidth
        style={{ marginTop: '15px', maxWidth: '500px' }}
        startAdornment={
          <InputAdornment position="start">
            <AssessmentOutlinedIcon style={{ color: 'black' }} />
          </InputAdornment>
        }>
        {allAssessments?.map((assessmentItem, index) => {
          return (
            <MenuItem
              key={assessmentItem.id}
              value={assessmentItem.id}
              //   sx={{ minWidth: '340px' }}
            >
              {assessmentItem.name}
            </MenuItem>
          );
        })}
      </Select>
      <FormControlLabel
        style={{ display: 'block' }}
        control={
          <Checkbox
            disabled={!assessment.id}
            checked={assessment.share_report}
            onChange={() => handleShareReportChange(index)}
          />
        }
        label="Share report with client"
      />
    </div>
  );
};

export default AssessmentItem;
