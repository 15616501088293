import { Button } from '@mui/material';
import React from 'react';

const ReceivedInviteActions = ({ inviteItem, inviteStatus, changeInviteStatus }) => {
  return (
    <>
      {inviteItem.status === inviteStatus.INVITED ? (
        <>
          <Button
            onClick={() => changeInviteStatus(inviteStatus.ACCEPTED)}
            fullWidth
            color="primary"
            variant="contained">
            Accept invitation
          </Button>
          <div className="margin-top--sm">
            <Button
              onClick={() => changeInviteStatus(inviteStatus.REJECTED)}
              type="text"
              fullWidth
              color="primary">
              Reject invitation
            </Button>
          </div>
        </>
      ) : null}
      {inviteItem.status === inviteStatus.ACCEPTED ? (
        <Button
          onClick={() => changeInviteStatus(inviteStatus.EXITED)}
          type="text"
          fullWidth
          color="primary">
          Exit
        </Button>
      ) : null}
    </>
  );
};

export default ReceivedInviteActions;
