import { collection, doc, getDoc, getDocs, query, setDoc } from 'firebase/firestore';
import { database } from '../../../helper/firebase.helper';

export const getAllAssessmentsFromDb = async () => {
  const data = await getDocs(query(collection(database, 'assessments'))).then((docs) => {
    const res = [];
    docs.forEach((doc) => res.push({ ...doc.data(), id: doc.id }));
    return res;
  });
  if (data && data.length) {
    return data;
  } else {
    return [];
  }
};

export const checkForPsyPackIntegration = async (orgId) => {
  const data = await getDoc(doc(database, `organisations/${orgId}/integrations/psypack`));
  return data.exists();
};

export const getSelfAssessmentsFromDb = async (orgId) => {
  const orgRef = doc(database, `organisations/${orgId}`);
  const orgDocSnap = await getDoc(orgRef);
  return orgDocSnap.data();
};

export const storeSelfAssessmentsToDb = async (orgId, data) => {
  const orgDocRef = doc(database, `organisations/${orgId}`);
  return await setDoc(orgDocRef, data, { merge: true });
};
