import { InputAdornment, TextField } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';

const Name = ({ control, setProfileChanged }) => {
  return (
    <>
      <div className="form-row flex-container">
        <div className="field margin-top--xs margin-bottom--xs">
          <Controller
            name="name"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label="Name"
                variant="outlined"
                size="small"
                value={value}
                required
                fullWidth
                // onChange={onChange}
                onChange={(e) => {
                  onChange(e);
                  setProfileChanged(true);
                }}
                error={!!error}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PersonOutlineOutlinedIcon color="primary" />
                    </InputAdornment>
                  )
                }}
              />
            )}
            rules={{ required: 'Please fill this field' }}
          />
        </div>
      </div>
      <style jsx>{`
        .form-row {
          justify-content: space-between;
          flex-wrap: wrap;
        }
        .field {
          width: 49%;
          min-width: 300px;
          position: relative;
        }

        @media only screen and (max-width: 600px) {
          .field {
            width: 100%;
          }
        }
      `}</style>
    </>
  );
};

export default Name;
