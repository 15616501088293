import React from 'react';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EventIcon from '@mui/icons-material/Event';
import PsychologyIcon from '@mui/icons-material/Psychology';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { getDate } from '../shared/clientNotes.service';
import { format } from 'date-fns';
import { calculateDurationInMinutes } from '../shared/clientNotes.service';
import StoreIcon from '@mui/icons-material/Store';

const MeetingDetails = ({ id, show, handleShowNameChange, clientData, meetingDetails }) => {
  return (
    <>
      <div className="meeting-details flex-container margin-top--md">
        <div style={{ marginRight: '50px' }}>
          <p
            className="detail-item flex-container"
            // style={{ wordBreak: ' break-word' }}
          >
            <FingerprintIcon color="primary" style={{ marginRight: '10px' }} />
            {id}
          </p>
          <p className="detail-item flex-container" style={{ display: show ? 'flex' : 'none' }}>
            <PersonIcon color="primary" style={{ marginRight: '10px' }} />
            {clientData?.name}
          </p>
          <p className="detail-item flex-container" style={{ display: show ? 'flex' : 'none' }}>
            <EmailIcon color="primary" style={{ marginRight: '10px' }} />
            {clientData?.email}
          </p>
          <p
            className="flex-container direction-column margin-top--sm"
            style={{ width: '100%', marginBottom: '15px', maxWidth: '295px' }}>
            <p style={{ marginLeft: '50px', zIndex: 9 }}>
              {show ? (
                <span
                  onClick={handleShowNameChange}
                  style={{
                    backgroundColor: '#f2f2f2',
                    padding: '0px 8px',
                    cursor: 'pointer'
                  }}>
                  <VisibilityOffIcon style={{ marginBottom: '-7px', marginRight: '5px' }} />
                  Hide
                </span>
              ) : (
                <span
                  onClick={handleShowNameChange}
                  style={{
                    backgroundColor: '#f2f2f2',
                    padding: '0px 8px',
                    cursor: 'pointer'
                  }}>
                  <VisibilityIcon style={{ marginBottom: '-7px', marginRight: '5px' }} />
                  Show
                </span>
              )}
            </p>
            <p style={{ borderTop: '2px solid lightgrey', marginTop: '-9px' }} />
          </p>
        </div>
        <div>
          <p className="detail-item flex-container">
            <StoreIcon color="primary" style={{ marginRight: '10px' }} />

            <a
              className="link"
              href={`https://${meetingDetails?.orgSiteDomain}.detalks.com`}
              target="_blank"
              rel="noreferrer">
              https://{meetingDetails?.orgSiteDomain}.detalks.com
            </a>
          </p>
          <p className="detail-item flex-container">
            <EventIcon color="primary" style={{ marginRight: '10px' }} />
            {meetingDetails?.meetingStart && (
              <>
                {' '}
                {getDate(meetingDetails?.meetingStart)} at{' '}
                {format(new Date(meetingDetails?.meetingStart), 'hh:mm a')} -{' '}
                {format(new Date(meetingDetails?.meetingEnd), 'hh:mm a')}
              </>
            )}
          </p>
          <p className="detail-item flex-container">
            <PsychologyIcon
              color="primary"
              style={{ transform: 'scaleX(-1)', marginRight: '10px' }}
            />
            {meetingDetails?.serviceName}&nbsp;
            <span
              style={{
                backgroundColor: 'rgb(216 230 253)',
                padding: '0px 6px',
                borderRadius: '5px'
              }}>
              {calculateDurationInMinutes(meetingDetails?.meetingStart, meetingDetails?.meetingEnd)}{' '}
              min
            </span>
          </p>
          <p className="detail-item flex-container">
            <LocationOnIcon color="primary" style={{ marginRight: '10px' }} />
            {meetingDetails?.locationName}
          </p>
        </div>
      </div>
      <style jsx>
        {`
          .meeting-details {
            // flex-wrap: wrap;
            justify-content: space-between;
            max-width: 800px;
          }
          .detail-item {
            // flex-basis: 45%;
            white-space: normal;
            // word-break: break-word;
            margin-top: 10px;
            align-items: center;
          }
          .link {
            text-decoration: underline;
            color: var(--primary-color);
            word-break: break-word;
          }
          @media only screen and (max-width: 1000px) {
            .meeting-details {
              flex-direction: column;
            }
          }
        `}
      </style>
    </>
  );
};

export default MeetingDetails;
