import { Button, IconButton } from '@mui/material';
import React, { useState } from 'react';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import toast from 'react-hot-toast';
import CloseIcon from '@mui/icons-material/Close';
import { deleteObject, getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { storage } from '../../../../../helper/firebase.helper';
import { useStoreState } from 'easy-peasy';
import Loader from '../../../../../shared/components/loader/Loader';

const AddFiles = ({
  filesToUpload,
  setFilesToUpload,
  filesToDelete,
  setFilesToDelete,
  signed,
  id,
  notes,
  setNotes
}) => {
  const user = useStoreState((state) => state.user);
  const [isLoading, setLoading] = useState(false);

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      if (event.target.files[0]?.size > 5242880) {
        toast.error(`File size shouldn't exceed 5MB`);
        event.target.files = '';
        return;
      } else {
        setLoading(true);
        const timestamp = Date.now();
        setFilesToUpload((prevFiles) => [
          { file: file, fileName: `${file.name}.${timestamp}` },
          ...prevFiles
        ]);
        setLoading(false);
        event.target.value = '';
      }
    }
  };

  const handleDeleteFilesToUpload = async (name) => {
    setFilesToUpload((prev) => prev.filter((file) => file.fileName !== name));
  };

  const handleDeleteFile = async (url) => {
    try {
      setLoading(true);
      setFilesToDelete((prevfilesUrl) => [...prevfilesUrl, url]);
      setNotes((prevNotes) => ({
        ...prevNotes,
        files: prevNotes.files.filter((file) => file.url !== url)
      }));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error('Error deleting file:', error.message);
    }
  };
  return (
    <div className="margin-top--md">
      {isLoading ? <Loader /> : null}
      <input
        type="file"
        multiple
        accept="application/pdf/image"
        onChange={handleFileChange}
        style={{ display: 'none' }}
        id="file-upload"
      />
      <label htmlFor="file-upload">
        {!signed && (
          <Button disabled={signed} startIcon={<AttachFileIcon />} component="span">
            Attach file
          </Button>
        )}
      </label>
      {(notes?.files?.length > 0 || filesToUpload?.length > 0) && (
        <div>
          {filesToUpload?.map((file, index) => (
            <p
              key={index}
              className="flex-container justify-between margin-top--xs"
              style={{
                backgroundColor: ' #f2f2f2',
                justifyContent: 'space-between',
                maxWidth: '400px',
                // alignItems: "center",
                padding: signed ? '5px 10px' : '2px 10px',
                borderRadius: '5px'
              }}>
              {/* <a href={file.url} target="_blank" rel="noreferrer"> */}
              <span style={{ color: 'black' }}> {file.file.name}</span>
              {/* </a> */}
              {!signed && (
                <IconButton size="small" onClick={() => handleDeleteFilesToUpload(file.fileName)}>
                  <CloseIcon style={{ fontSize: '18px' }} />
                </IconButton>
              )}
            </p>
          ))}
          {notes?.files?.map((file, index) => (
            <p
              key={index}
              className="flex-container justify-between margin-top--xs"
              style={{
                backgroundColor: ' #f2f2f2',
                justifyContent: 'space-between',
                maxWidth: '400px',
                // alignItems: "center",
                padding: signed ? '5px 10px' : '2px 10px',
                borderRadius: '5px'
              }}>
              <a href={file.url} target="_blank" rel="noreferrer">
                <span style={{ color: '#0884ff' }}> {file.name}</span>
              </a>
              {!signed && (
                <IconButton size="small" onClick={() => handleDeleteFile(file.url)}>
                  <CloseIcon style={{ fontSize: '18px' }} />
                </IconButton>
              )}
            </p>
          ))}
        </div>
      )}
    </div>
  );
};

export default AddFiles;
