import { Button, Chip, InputAdornment, TextField, Tooltip } from '@mui/material';
import React, { useState } from 'react';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import toast from 'react-hot-toast';
import InfoIcon from '@mui/icons-material/Info';

const EmailDomains = ({ emailDomains, setEmailDomains, setSettingsChanged }) => {
  const [input, setInput] = useState('');

  const handleInput = (e) => {
    setInput(e.target.value);
  };

  const handleAddInput = () => {
    const inputArray = input
      .split(',')
      .map((word) => word.trim())
      .filter((word) => word !== '');

    const invalidDomains = [];
    const validDomains = [];

    inputArray.forEach((word) => {
      if (word.includes('.')) {
        validDomains.push(word);
      } else {
        invalidDomains.push(word);
      }
    });
    if (invalidDomains.length > 0) {
      toast.error(`Invalid domains: ${invalidDomains.join(', ')}`);
    }
    setEmailDomains((prevDomains) =>
      Array.isArray(prevDomains) ? [...prevDomains, ...validDomains] : [...validDomains]
    );
    // inputArray.forEach((word) => emailDomains.push(word));
    setInput('');
    setSettingsChanged(true);
  };

  const handleDelete = (index, emailDomain) => {
    const updatedEmailDomains = emailDomains?.filter(
      (emaildomain, i) => emaildomain !== emailDomain
    );
    setEmailDomains([...updatedEmailDomains]);
    setSettingsChanged(true);
  };

  return (
    <>
      <p className="heading margin-top--sm">
        Set Email Domains to Restrict Clients{' '}
        <Tooltip
          title="Restrict access to your services by allowing only clients with specific email domains (e.g., @example.com). This feature is ideal for organizations or practices that serve a defined group, such as employees or members of a specific company or institution. Clients with domains not listed here will not be able to book or access restricted services."
          aria-label="edit"
          arrow>
          <InfoIcon color="secondary" fontSize="small" style={{ marginBottom: '-5px' }} />
        </Tooltip>
      </p>
      <div className="field-container flex-container margin-top--md">
        <TextField
          label="Email Domains"
          fullWidth
          placeholder="Add email domains"
          value={input}
          onChange={handleInput}
          //   size="small"
          style={{ maxWidth: '500px' }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <EmailOutlinedIcon color="primary" />
              </InputAdornment>
            )
          }}
        />
        <div className="add-button">
          <Button disabled={!input} size="small" variant="contained" onClick={handleAddInput}>
            Add
          </Button>
        </div>
      </div>
      <div className="flex-container chip-wrapper">
        {emailDomains?.map((emailDomain, index) => (
          <div key={index} className="chip-container margin-top--xs margin-bottom--xs">
            <Chip label={emailDomain} onDelete={() => handleDelete(index, emailDomain)} />
          </div>
        ))}
      </div>
      <style jsx>{`
        .heading {
          border-bottom: 1px solid var(--border-gray);
          padding-bottom: 10px;
        }
        .add-button {
          margin-left: 5px;
          margin-top: 10px;
        }
        .field-container {
          // max-width: 450px;
        }
        .chip-container {
          margin-right: 5px;
        }
        .chip-wrapper {
          flex-wrap: wrap;
        }
      `}</style>
    </>
  );
};

export default EmailDomains;
