import { Button, Fab } from '@mui/material';
import { useStoreState } from 'easy-peasy';
import React, { useEffect, useState } from 'react';
import Loader from '../../../shared/components/loader/Loader';
import toast from 'react-hot-toast';
import AssessmentItem from './assessment-item/AssessmentItem';
import { Helmet } from 'react-helmet';
import {
  checkForPsyPackIntegration,
  getAllAssessmentsFromDb,
  getSelfAssessmentsFromDb,
  storeSelfAssessmentsToDb
} from './assessments.service';
import { Link } from 'react-router-dom';

const SelfAssessments = () => {
  const practitionerData = useStoreState((state) => state.practitionerData);
  const [selfAssessments, setSelfAssessments] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [assessmentsChanged, setAssessmentsChanged] = useState(false);
  const [allAssessments, setAllAssessments] = useState([]);
  const [psyPackIntegrated, setPsyPackIntegrated] = useState(false);

  const getAllAssessments = async () => {
    setLoading(true);
    const data = await getAllAssessmentsFromDb();
    if (data && data.length) {
      setAllAssessments(data);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllAssessments();
  }, []);

  const getSelfAssessments = async () => {
    getSelfAssessmentsFromDb(practitionerData.own_organisation).then((res) => {
      if (res?.self_assessments) {
        setSelfAssessments(res.self_assessments);
      }
    });
  };

  useEffect(() => {
    if (practitionerData) {
      getSelfAssessments();
      checkForPsyPackIntegration(practitionerData.own_organisation).then((res) => {
        setPsyPackIntegrated(res);
      });
    }
  }, [practitionerData]);

  const handleAddAssessment = () => {
    const newAssessment = {
      id: '',
      share_report: false,
      order: selfAssessments?.length + 1 || 1
    };
    setSelfAssessments((prevAssessments) => [...prevAssessments, newAssessment]);
    setAssessmentsChanged(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // if (selfAssessments.length < 1) {
    //   toast.error('Add atleast one assessment');
    //   return;
    // }
    const fixedAssessments = selfAssessments.map((assessment, index) => ({
      ...assessment,
      order: index + 1
    }));
    try {
      await toast.promise(
        storeSelfAssessmentsToDb(practitionerData.own_organisation, {
          self_assessments: fixedAssessments
        }),
        {
          success: 'Assessments saved!',
          loading: 'Saving ...',
          error: 'Failed to save!'
        }
      );
      const updatedData = await getSelfAssessmentsFromDb(practitionerData.own_organisation);
      if (updatedData.self_assessments) {
        setSelfAssessments(updatedData.self_assessments);
      }
      setAssessmentsChanged(false);
    } catch (error) {
      toast.error('Error saving data:', error.message);
    }
  };

  return (
    <div className="margin-top--xs">
      <Helmet>
        <title>Self Assessments - DeTalks</title>
      </Helmet>
      {isLoading ? <Loader /> : null}

      {psyPackIntegrated ? (
        <form onSubmit={handleSubmit}>
          {selfAssessments?.map((assessment, index) => (
            <AssessmentItem
              index={index}
              key={index}
              assessment={assessment}
              selfAssessments={selfAssessments}
              setSelfAssessments={setSelfAssessments}
              allAssessments={allAssessments}
              setAssessmentsChanged={setAssessmentsChanged}
            />
          ))}

          <Button
            style={{ paddingLeft: '0px', marginTop: '10px' }}
            color="primary"
            onClick={handleAddAssessment}>
            Add new assessment
          </Button>

          <div className="floating-button">
            <Fab
              style={{ width: '120px' }}
              variant="extended"
              color="primary"
              type="submit"
              disabled={
                !assessmentsChanged
                // || selfAssessments?.length < 1
              }>
              Save
            </Fab>
          </div>
        </form>
      ) : (
        <p className="text--md">
          Please{' '}
          <Link style={{ color: 'var(--primary-color)' }} to="/dashboard/integrations">
            integrate PsyPack
          </Link>{' '}
          to add self-assessments.
        </p>
      )}
      <style jsx>{`
        .floating-button {
          position: fixed;
          bottom: 60px;
          right: 10%;
        }
      `}</style>
    </div>
  );
};

export default SelfAssessments;
