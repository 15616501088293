import React, { useEffect, useState } from 'react';
import { Button, Fab } from '@mui/material';
import toast from 'react-hot-toast';
import { collection, doc, getDocs, setDoc } from 'firebase/firestore';
import { useStoreState } from 'easy-peasy';
import { database } from '../../../helper/firebase.helper';
import { getAllServices } from '../../../helper/api.helper';
import Loader from '../../../shared/components/loader/Loader';
import ServiceItem from './service-item/ServiceItem';

const ServiceList = () => {
  const practitioner = useStoreState((state) => state.user);
  const [services, setServices] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [locations, setLocations] = useState([]);
  const [serviceChanged, setServiceChanged] = useState(false);

  const getServices = () => {
    setLoading(true);
    getAllServices()
      .then((res) => res.json())
      .then((res) => {
        if (res.status === 'success') {
          setServices(res.data.services);
          setLocations(res.data.locations);
          setLoading(false);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.message);
      });
  };

  useEffect(() => {
    getServices();
  }, []);

  const handleAddService = () => {
    const newService = {
      id: doc(collection(database, 'services')).id,
      title: '',
      time: '',
      active: true,
      preEventBuffer: 0,
      postEventBuffer: 0,
      locations: [],
      noNewClients: false
    };
    setServices((prevServices) => [...prevServices, newService]);
    setServiceChanged(true);
  };

  const storeToDb = async (services) => {
    await Promise.all(
      services.map((service) =>
        setDoc(doc(database, 'services', service.id), {
          ...service,
          practitioner: practitioner.uid,
          updateTime: Date.now(),
          locations: service.locations?.map((location) => ({
            id: location.id,
            price: location.price
          }))
        })
      )
    );

    const hasActiveServices = services?.some((service) => service.active);

    if (hasActiveServices) {
      const progressDocRef = doc(database, `practitioners/${practitioner?.uid}/details/progress`);
      await setDoc(progressDocRef, { services: true }, { merge: true });
    } else {
      const progressDocRef = doc(database, `practitioners/${practitioner?.uid}/details/progress`);
      await setDoc(progressDocRef, { services: false }, { merge: true });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const invalidService = services.find((service) => service.locations.length === 0);

    if (invalidService) {
      toast.error(`Please select at least one location for the service: ${invalidService.title}`);
      return;
    }
    setServiceChanged(false);
    toast.promise(storeToDb(services), {
      success: 'Services saved!',
      loading: 'Saving ...',
      error: 'Failed to save!'
    });
  };

  return (
    <div
      className="margin-top--xs"
      // style={{ maxWidth: "500px" }}
    >
      {isLoading ? <Loader /> : null}

      <form onSubmit={handleSubmit}>
        {services?.map(
          (service, i) =>
            service?.active === true && (
              <ServiceItem
                i={i}
                key={i}
                service={service}
                locations={locations}
                setServices={setServices}
                setServiceChanged={setServiceChanged}
              />
            )
        )}

        <Button
          style={{ fontWeight: 'bold' }}
          color="primary"
          onClick={handleAddService}
          variant="outlined">
          Add new service
        </Button>

        <div className="floating-button">
          <Fab
            style={{ width: '120px' }}
            variant="extended"
            color="primary"
            type="submit"
            disabled={!serviceChanged}>
            Save
          </Fab>
        </div>
      </form>
      <style jsx>{`
        .floating-button {
          position: fixed;
          bottom: 60px;
          right: 10%;
        }
      `}</style>
    </div>
  );
};

export default ServiceList;
