import React from 'react';
import SentInviteActions from './sent-invite-actions/SentInviteActions';
import ReceivedInviteActions from './received-invite-actions/ReceivedInviteActions';

const InviteActions = ({
  practitioner,
  inviteItem,
  inviteStatus,
  changeInviteStatus,
  invitePract
}) => {
  return (
    <div className="item text--sm">
      {practitioner.uid === inviteItem.practitioner ? (
        <SentInviteActions
          inviteItem={inviteItem}
          inviteStatus={inviteStatus}
          changeInviteStatus={changeInviteStatus}
          invitePract={invitePract}
        />
      ) : practitioner.email === inviteItem.email ? (
        <ReceivedInviteActions
          inviteItem={inviteItem}
          inviteStatus={inviteStatus}
          changeInviteStatus={changeInviteStatus}
        />
      ) : null}
      <style jsx>{`
        .item {
          flex: 1;
          text-overflow: ellipsis;
          height: fit-content;
        }
      `}</style>
    </div>
  );
};

export default InviteActions;
