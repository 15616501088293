import React from 'react';
import toast from 'react-hot-toast';
import { acceptInvite, invitePractitioner, updateInvite } from '../../../../../helper/api.helper';
import { Hidden } from '@mui/material';
import InviteActions from './invite-actions/InviteActions';
import InviteStatus from './invite-status/InviteStatus';
import PractitionerName from './practitioner-name/PractitionerName';
import PractitionerEmail from './practitioner-email/PractitionerEmail';

const InviteHistoryItem = ({
  inviteItem,
  practitioner
  //   isInGroupPractice,
  //   seatsAvailable,
  //   usedSeats,
}) => {
  const statusLabel = {
    1: 'Invited',
    2: 'Accepted',
    3: 'Rejected',
    4: 'Expired',
    5: 'Withdrawn',
    6: 'Removed',
    7: 'Exited'
  };

  const inviteStatus = {
    INVITED: 1,
    ACCEPTED: 2,
    REJECTED: 3,
    EXPIRED: 4,
    WITHDRAWN: 5,
    REMOVED: 6,
    EXITED: 7
  };

  const statusColor = {
    1: '#ffcc00',
    2: '#34c759',
    3: '#ff3b30',
    4: '#ff3b30',
    5: '#ff3b30',
    6: '#ff3b30',
    7: '#ff3b30'
  };

  const changeInviteStatus = async (status) => {
    if (status === inviteStatus.ACCEPTED) {
      toast.loading('Accepting invite');
      acceptInvite({ inviteId: inviteItem.id })
        .then((res) => res.json())
        .then((res) => {
          toast.dismiss();
          if (res.status === 'success') {
            toast.success('Invite accepted');
          } else {
            toast.error(res.message);
          }
        })
        .catch((err) => {
          toast.error(`Failed to accept - ${err.message}`);
        });
    } else if (status === inviteStatus.REJECTED) {
      toast.loading('Rejecting invite');
      updateInvite({ inviteId: inviteItem.id, status: status })
        .then((res) => res.json())
        .then((res) => {
          toast.dismiss();
          if (res.status === 'success') {
            toast.success('Rejected successfully');
          } else {
            toast.error(res.message);
          }
        })
        .catch((err) => {
          toast.error(`Failed to reject - ${err.message}`);
        });
    } else if (inviteItem.status === inviteStatus.ACCEPTED && status === inviteStatus.EXITED) {
      toast.loading('Exiting');
      updateInvite({ inviteId: inviteItem.id, status: status })
        .then((res) => res.json())
        .then((res) => {
          toast.dismiss();
          if (res.status === 'success') {
            toast.success('Exited successfully');
          } else {
            toast.error(res.message);
          }
        })
        .catch((err) => {
          toast.error(`Failed to exit - ${err.message}`);
        });
    } else if (status === inviteStatus.REMOVED) {
      toast.loading('Removing practitioner');
      updateInvite({ inviteId: inviteItem.id, status: status })
        .then((res) => res.json())
        .then((res) => {
          toast.dismiss();
          if (res.status === 'success') {
            toast.success('Practitioner removed successfully');
          } else {
            toast.error(res.message);
          }
        })
        .catch((err) => {
          toast.error(`Failed to remove - ${err.message}`);
        });
    } else if (status === inviteStatus.WITHDRAWN) {
      toast.loading('Withdrawing invitation');
      updateInvite({ inviteId: inviteItem.id, status: status })
        .then((res) => res.json())
        .then((res) => {
          toast.dismiss();
          if (res.status === 'success') {
            toast.success('Withdrawn successfully');
          } else {
            toast.error(res.message);
          }
        })
        .catch((err) => {
          toast.error(`Failed to withdraw - ${err.message}`);
        });
    }
  };

  const invitePract = () => {
    toast.loading('Inviting practitioner...');
    invitePractitioner({ email: inviteItem.email })
      .then((res) => res.json())
      .then((res) => {
        toast.dismiss();
        if (res.status === 'success') {
          toast.success('Practitioner invited');
        } else {
          toast.error(res.message);
        }
      })
      .catch((err) => {
        toast.error(`Failed to invite - ${err.message}`);
      });
  };

  return (
    <div className="row margin-top--md margin-bottom--md flex-container">
      <PractitionerEmail
        practitioner={practitioner}
        inviteItem={inviteItem}
        statusColor={statusColor}
      />
      <Hidden smDown>
        <PractitionerName practitioner={practitioner} inviteItem={inviteItem} />
      </Hidden>
      <Hidden smDown>
        <InviteStatus statusColor={statusColor} inviteItem={inviteItem} statusLabel={statusLabel} />
      </Hidden>
      <InviteActions
        practitioner={practitioner}
        inviteItem={inviteItem}
        inviteStatus={inviteStatus}
        changeInviteStatus={changeInviteStatus}
        invitePract={invitePract}
      />
      <div />
      <style jsx>{`
        .row {
          justify-content: start;
        }
      `}</style>
    </div>
  );
};

export default InviteHistoryItem;
