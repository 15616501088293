import { doc, getDoc } from 'firebase/firestore';
import { auth, database } from '../../../../helper/firebase.helper';

export const getProgressDataFromDb = async (practitionerId) => {
  const progressDocRef = doc(database, `practitioners/${practitionerId}/details/progress`);
  const progressDocSnap = await getDoc(progressDocRef);

  if (progressDocSnap.exists()) {
    return progressDocSnap.data();
  } else {
    console.log('No such document!');
    return null;
  }
};

export const getDomain = async (orgId) => {
  const domainDocRef = doc(database, `organisations`, orgId);
  const domainDocSnap = await getDoc(domainDocRef);
  return {
    siteDomain: domainDocSnap.get('siteDomain')
  };
};
