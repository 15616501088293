import React from 'react';

const PractitionerName = ({ practitioner, inviteItem }) => {
  return (
    <>
      {practitioner.uid === inviteItem.practitioner ? (
        <p className="item text--sm" style={{ wordBreak: 'break-word' }}>
          {inviteItem['inviteeName']}
        </p>
      ) : practitioner.email === inviteItem.email ? (
        <p className="item text--sm" style={{ wordBreak: 'break-word' }}>
          {inviteItem['practitionerName']}
        </p>
      ) : null}
      <style jsx>{`
        .item {
          flex: 1;
          text-overflow: ellipsis;
          height: fit-content;
        }
      `}</style>
    </>
  );
};

export default PractitionerName;
