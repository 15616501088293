export const meetingTypeEnum = {
  TELEHEALTH: 0,
  IN_PERSON: 1
};

export const statusEnum = {
  INITIATED: 0,
  COMPLETED: 1,
  EXPIRED: 2,
  CANCELLED: 3
};
