import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import toast from 'react-hot-toast';

import psypackIcon from '../../../assets/psypackicon.png';
import { verifyPsypackIntegration } from '../../../helper/api.helper';

const PsypackIntegration = ({ open, handleOpen, setIntegrations }) => {
  const handleClose = () => {
    handleOpen(false);
  };

  const verifyIntegration = (clientId, secret) => {
    toast.promise(
      verifyPsypackIntegration({ clientId, secret })
        .then((res) => res.json())
        .then((res) => {
          if (res.status === 'fail') {
            throw Error(res.message);
          } else {
            setIntegrations((integrations) => [...integrations, 'psypack']);
            handleClose();
          }
        }),
      {
        loading: 'Verifying integration',
        success: 'PsyPack integrated',
        error: (err) => err.message
      }
    );
  };
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            verifyIntegration(formJson.clientId, formJson.clientSecret);
          }
        }}>
        <DialogTitle>
          <div className="flex-container align-center">
            <img style={{ maxWidth: '40px' }} src={psypackIcon}></img>
            <p>Integrate PsyPack</p>
          </div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <p className="margin-bottom--lg">
              You can access the API credentials of PsyPack by clicking on &quot;View API
              credentials&quot; in the{' '}
              <a
                target="_blank"
                style={{ color: 'var(--primary-color)' }}
                href="https://app.psypack.com/dashboard/plan"
                rel="noreferrer">
                Plan
              </a>{' '}
              page
            </p>
          </DialogContentText>
          <TextField
            margin="normal"
            required
            id="clientId"
            name="clientId"
            label="Client ID"
            type="text"
            size="small"
            fullWidth
            variant="outlined"
          />
          <TextField
            margin="normal"
            required
            id="clientSecret"
            name="clientSecret"
            label="Client secret"
            type="text"
            size="small"
            fullWidth
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button variant="contained" color="primary" type="submit">
            Integrate
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PsypackIntegration;
