import { AppBar, Tab, Tabs } from '@mui/material';
import React from 'react';
import Invites from './invites/Invites';
import { NavLink, Navigate, Route, Routes } from 'react-router-dom';
import DomainAndBranding from './domain-branding/DomainAndBranding';
import Settings from './settings/Settings';
import SelfAssessments from './self-assessments/SelfAssessments';

const MyPractice = () => {
  return (
    <>
      {window.innerWidth < 960 && (
        <AppBar position="sticky" color="inherit" elevation={2}>
          <Tabs
            variant="fullWidth"
            indicatorColor="primary"
            textColor="primary"
            aria-label="nav tabs">
            <Tab
              component={NavLink}
              label="Domain And Branding"
              value="domainandbranding"
              to="/dashboard/practice/domain-branding"
            />
            <Tab
              component={NavLink}
              label="Invites"
              value="invites"
              to="/dashboard/practice/invites"
            />
            <Tab
              component={NavLink}
              label="Self Assessments"
              value="selfassessments"
              to="/dashboard/practice/self-assessments"
            />
            <Tab
              component={NavLink}
              label="Settings"
              value="settings"
              to="/dashboard/practice/settings"
            />
          </Tabs>
        </AppBar>
      )}
      <div style={{ padding: 'var(--space-unit)' }}>
        <Routes>
          <Route index element={<Navigate to="domain-branding" replace />} />
          <Route path="domain-branding/*" element={<DomainAndBranding />} />
          <Route path="invites/*" element={<Invites />} />
          <Route path="settings/*" element={<Settings />} />
          <Route path="self-assessments/*" element={<SelfAssessments />} />
        </Routes>
      </div>
      <style jsx>{`
        :global(.active) {
          color: var(--primary-color);
        }
      `}</style>
    </>
  );
};

export default MyPractice;
