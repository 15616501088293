import TextField from '@mui/material/TextField';
import { useForm, Controller, useWatch } from 'react-hook-form';
import { Button } from '@mui/material';
import EastIcon from '@mui/icons-material/East';
import InputAdornment from '@mui/material/InputAdornment';
import SchoolIcon from '@mui/icons-material/School';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import { doc, setDoc } from 'firebase/firestore';
import { auth, database } from '../../../helper/firebase.helper';
import { useStoreState } from 'easy-peasy';
import { useEffect, useState } from 'react';

const Education = ({ activeTab, loading, setLoading, goNext }) => {
  const { handleSubmit, setValue, control, getValues } = useForm();
  const [education, setEducation] = useState(false);
  const user = useStoreState((state) => state.user);
  const userId = user?.uid;

  const watchedEducationValue = useWatch({
    control,
    name: `education.${0}.education`
  });

  useEffect(() => {
    const educationValue = getValues(`education.${0}.education`);
    if (educationValue) {
      setEducation(true);
    } else {
      setEducation(false);
    }
  }, [getValues, watchedEducationValue]);

  const onSubmit = async (data) => {
    setLoading(true);
    const personalDocRef = doc(database, `practitioners/${userId}/details/personal`);
    await setDoc(personalDocRef, data, { merge: true });
    const progressDocRef = doc(database, `practitioners/${userId}/details/progress`);
    await setDoc(progressDocRef, { profile: true }, { merge: true });
    goNext();
    setLoading(false);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="margin-top--xs margin-bottom--md full-width">
        <Controller
          name={`education.${0}.education`}
          control={control}
          defaultValue=""
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              label="Education/Qualification"
              variant="outlined"
              size="small"
              placeholder="M.Phil. Psychology/ M.SC. Psychology/ Ph.D. Psychology ..."
              required
              value={value}
              fullWidth
              onChange={onChange}
              error={!!error}
              helperText={error ? error.message : null}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SchoolIcon />
                  </InputAdornment>
                )
              }}
            />
          )}
          rules={{ required: 'Please fill this field' }}
        />
      </div>

      <Button
        fullWidth
        variant="contained"
        type="submit"
        color="primary"
        disabled={!education || loading || activeTab !== 0}>
        <p className="text--md">Next</p> <EastIcon style={{ marginLeft: '20px' }} />
      </Button>
    </form>
  );
};

export default Education;
