/* eslint-disable no-unused-vars */
import React, { memo, useMemo, useRef } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import katex from 'katex';
import 'katex/dist/katex.min.css';

window.katex = katex;

// eslint-disable-next-line react/display-name
const RichText = memo(({ value, setValue, disabled, placeholder }) => {
  const modules = useMemo(
    () => ({
      clipboard: {
        matchVisual: false
      },
      formula: true,
      toolbar: {
        container: [
          [{ header: [1, 2, false] }],
          ['bold', 'italic', 'underline', 'strike', 'blockquote'],
          [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
          ['link', 'formula'],
          ['clean']
        ]
      }
    }),
    []
  );

  return (
    <div className="margin-top--md">
      <ReactQuill
        theme="snow"
        value={value}
        modules={modules}
        formats={[
          'header',
          'bold',
          'italic',
          'underline',
          'strike',
          'blockquote',
          'list',
          'bullet',
          'indent',
          'link',
          'formula'
        ]}
        onChange={setValue}
        placeholder={placeholder}
        readOnly={disabled}
      />
      <style jsx>{`
        :global(.ql-editor) {
          min-height: 80px;
        }
      `}</style>
    </div>
  );
});

export default RichText;
