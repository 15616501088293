import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { useStoreState } from 'easy-peasy';

import {
  fetchClientDetailsFromDb,
  fetchFieldsFromDb,
  getDate,
  getStatusColor
} from './clientHistory.service';
import { Link, useNavigate, useParams } from 'react-router-dom';
import CancelPopup from './cancel-popup/CancelPopup';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { cancelMeeting, shareReportToClient } from '../../../helper/api.helper';
import toast from 'react-hot-toast';
import Loader from '../../../shared/components/loader/Loader';
import { format } from 'date-fns';

const HistoryItem = ({ historyItem }) => {
  const { id: clientId } = useParams();
  const [cancelled, setCancelled] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [text, setText] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [intakeFormFields, setIntakeFormFields] = useState(null);

  const practitioner = useStoreState((state) => state.user);

  useEffect(() => {
    if (historyItem.type === 2 && historyItem.status === 3) {
      setCancelled(true);
      if (historyItem.cancellationReason) {
        setText(historyItem.cancellationReason);
      }
    }
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleSave = () => {
    console.log(text);
    setCancelled(true);
    setLoading(true);
    cancelMeeting({ meetingId: historyItem.id, reason: text })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === 'success') {
          toast.success('Meeting cancelled successfully');
          setLoading(false);
        } else {
          setLoading(false);
          toast.error(res.message);
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.message);
      });
    // handleClose();
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const navigate = useNavigate();

  const [missingMandatory, setMissingMandatory] = useState(false);

  useEffect(() => {
    const checkMandatoryFields = async () => {
      try {
        const clientDetails = await fetchClientDetailsFromDb(clientId);
        const fields = await fetchFieldsFromDb(historyItem.organisation);
        setIntakeFormFields(fields);
        const missingField = fields.some((field) => field.mandatory && !clientDetails[field.id]);

        setMissingMandatory(missingField);
      } catch (error) {
        console.error('Error checking mandatory fields:', error);
      }
    };

    checkMandatoryFields();
  }, [clientId, historyItem.organisation]);

  const handleClick = () => {
    if (historyItem.type === 0 && historyItem.status === 1) {
      const url = historyItem.report_url;
      window.open(url);
    } else if (historyItem.type === 1 && historyItem.status === 1) {
      const url = historyItem.certificate_url;
      window.open(url);
    }
  };

  const shareReport = () => {
    toast.promise(
      shareReportToClient({ activityId: historyItem.id })
        .then((res) => res.json())
        .then((res) => {
          if (res.status === 'fail') {
            throw Error(res.message);
          }
        }),
      {
        loading: 'Sharing report',
        success: 'Report shared successfully',
        error: 'Failed to share report'
      }
    );
  };

  return (
    <div className="flex-container text--sm  outer-container margin-top--md ">
      {isLoading ? <Loader /> : null}

      <div className="icon-div">
        <div
          className="icon flex-container justify-center align-center"
          style={{ backgroundColor: getStatusColor(historyItem.status) }}>
          {historyItem.client_email.charAt(0).toUpperCase()}
        </div>
      </div>
      <div className="activity-div inner-div">
        {historyItem.type === 2 && (
          <>
            <p className="semi-bold">
              {format(new Date(historyItem.meetingStart), 'hh:mm a')} -{' '}
              {format(new Date(historyItem.meetingEnd), 'hh:mm a')}
            </p>
            <p className="semi-bold"> {getDate(historyItem.meetingStart)} </p>
          </>
        )}
        <p className="semi-bold">
          {historyItem.type === 0
            ? 'Self-Assessment'
            : historyItem.type === 1
              ? 'Short Course'
              : historyItem.type === 2
                ? 'Booking'
                : null}
        </p>
        <p className="semi-bold">{historyItem.name}</p>
        {historyItem.type === 2 && <p className="semi-bold">{historyItem.serviceName}</p>}
        {historyItem.type === 2 && historyItem.orgSiteDomain && (
          <p>
            <a href={`https://${historyItem.orgSiteDomain}.${process.env.REACT_APP_DOMAIN}`}>
              {`${historyItem.orgSiteDomain}.${process.env.REACT_APP_DOMAIN}`}
            </a>
          </p>
        )}
        {historyItem.type === 2 && historyItem.practitionerEmail && (
          <p>{historyItem.practitionerEmail}</p>
        )}
        <p className="semi-bold">{historyItem.activityName}</p>
      </div>
      <div className="status-div inner-div">
        {historyItem.type === 2 ? (
          <span className="semi-bold" style={{ color: getStatusColor(historyItem.status) }}>
            {historyItem.status === 0
              ? 'Scheduled'
              : historyItem.status === 1
                ? 'Completed'
                : historyItem.status === 2
                  ? 'Expired'
                  : historyItem.status === 3
                    ? 'Cancelled'
                    : null}
          </span>
        ) : (
          <span className="semi-bold" style={{ color: getStatusColor(historyItem.status) }}>
            {historyItem.status === 0
              ? 'Initiated'
              : historyItem.status === 1
                ? 'Completed'
                : historyItem.status === 2
                  ? 'Expired'
                  : null}
          </span>
        )}
        &nbsp;&nbsp;
        <p>{getDate(historyItem.update_time)}</p>
        {historyItem.type === 2 && (
          <>
            <p className="semi-bold"> {historyItem?.locationName} </p>
            {/* <p className="semi-bold"> Paid Online </p> */}
          </>
        )}
        <div className="semi-bold">
          {historyItem.key_metrics && historyItem.score ? (
            <div>
              Score : {historyItem.score}
              {historyItem.key_metrics.map((item, index) => {
                return item.key ? (
                  <p key={index}>
                    {item.key}: {item.value}
                  </p>
                ) : null;
              })}
            </div>
          ) : historyItem.key_metrics ? (
            historyItem.key_metrics.map((item, index) => {
              return item.key ? (
                <p key={index}>
                  {item.key}: {item.value}
                </p>
              ) : null;
            })
          ) : null}
          {!historyItem.key_metrics && (historyItem.score || historyItem.score === 0)
            ? `Score : ${historyItem.score}`
            : null}
        </div>
      </div>

      <div className="actions-div inner-div">
        <div className={historyItem.type !== 2 && historyItem.status === 2 ? 'btn' : null}>
          {(historyItem.type === 1 && historyItem.status === 1) ||
          (historyItem.type === 0 && historyItem.status === 1) ? (
            <Button
              style={{ maxHeight: '50px' }}
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleClick}
              size="small">
              {historyItem.type === 0 && historyItem.status === 1
                ? 'View Report'
                : historyItem.type === 1 && historyItem.status === 1
                  ? 'View Certificate'
                  : null}
            </Button>
          ) : null}
          {historyItem.assignment_url && (
            <Button
              target="_blank"
              size="small"
              fullWidth
              href={historyItem.assignment_url}
              style={{
                justifyContent: 'flex-start',
                textAlign: 'left'
              }}>
              View assignment
            </Button>
          )}
          {historyItem.share_report === false && (
            <Button
              target="_blank"
              size="small"
              fullWidth
              onClick={shareReport}
              style={{
                justifyContent: 'flex-start',
                textAlign: 'left'
              }}>
              Share report with client
            </Button>
          )}
          {historyItem.report_url && historyItem.type !== 0 ? (
            <Button
              size="small"
              target="_blank"
              fullWidth
              href={historyItem.report_url}
              style={{
                justifyContent: 'flex-start',
                textAlign: 'left'
              }}>
              View report
            </Button>
          ) : null}
          {historyItem.type === 2 && (
            <>
              {historyItem.meetingLink && historyItem.practitioner === practitioner.uid && (
                <Button
                  href={historyItem.meetingLink}
                  target="_blank"
                  style={{ maxHeight: '50px' }}
                  fullWidth
                  variant="contained"
                  color="primary"
                  size="small">
                  Join meeting
                </Button>
              )}

              {historyItem.meetingLink && historyItem.practitioner !== practitioner.uid && (
                <Button
                  fullWidth
                  href={historyItem.meetingLink}
                  target="_blank"
                  size="small"
                  style={{
                    justifyContent: 'flex-start',
                    textAlign: 'left'
                  }}>
                  Meeting link
                </Button>
              )}
              {historyItem.practitioner === practitioner.uid && (
                <Button
                  fullWidth
                  size="small"
                  style={{
                    justifyContent: 'flex-start',
                    textAlign: 'left'
                  }}>
                  <Link to={`notes/${historyItem.id}`}>Add Notes</Link>
                </Button>
              )}
              {/* <Button
                fullWidth
                size="small"
                style={{
                  justifyContent: 'flex-start',
                  textAlign: 'left'
                }}>
                Reschedule meeting
              </Button> */}
              {historyItem.practitioner === practitioner.uid && (
                <Button
                  onClick={handleClickOpen}
                  fullWidth
                  size="small"
                  style={{
                    justifyContent: 'flex-start',
                    textAlign: 'left',
                    color: '#ff0000'
                  }}>
                  {cancelled ? 'Cancellation Note' : 'Cancel Meeting'}
                </Button>
              )}
            </>
          )}
        </div>
        {intakeFormFields && intakeFormFields.length > 0 && (
          <Button
            fullWidth
            size="small"
            style={{
              justifyContent: 'flex-start',
              textAlign: 'left'
            }}
            endIcon={missingMandatory ? <WarningAmberIcon style={{ color: '#ffda00' }} /> : null}>
            <Link to={`intake-tasks`}>Intake Tasks</Link>
          </Button>
        )}
      </div>

      <CancelPopup
        setText={setText}
        text={text}
        open={open}
        handleClose={handleClose}
        handleSave={handleSave}
        handleOpen={handleOpen}
        cancelled={cancelled}
        bookingItem={historyItem}
      />
      <style jsx>
        {`
          .outer-container {
            border-bottom: 1px solid var(--border-gray);
            padding-bottom: 10px;
            justify-content: space-between;
          }
          .icon-div {
            width: 4%;
          }
          .activity-div {
            width: 25%;
            overflow: hidden;
          }

          .status-div {
            width: 35%;
          }
          .actions-div {
            width: 25%;
          }
          .inner-div {
            color: var(--gray-darkest);
          }
          .icon {
            background-color: var(--primary-color);
            color: white;
            fontSize: '24px'
            padding: 10px;
            width:30px;
            height:30px;
            border-radius: 50%;
          }
          .btn{
            visibility:hidden;
          }
          .semi-bold {
            font-weight: 500;
          }

          @media only screen and ( max-width : 900px ) {
            .activity-div {
              width: 40% !important;
            }
            .source-div {
              display: none ;
            }
            .status-div {
              display: none;
            }
            .actions-div {
              width: 42% !important;
            }
          }

          @media only screen and (max-width: 350px) {
            .icon-div {
              display: none;
            }
            .actions-div {
              width: 54% !important;
            }
          }
        `}
      </style>
    </div>
  );
};

export default HistoryItem;
