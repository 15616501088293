import React, { useEffect, useState } from 'react';
import { Controller, useFieldArray, useWatch } from 'react-hook-form';
import {
  TextField,
  InputAdornment,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from '@mui/material';
import WorkspacePremiumOutlinedIcon from '@mui/icons-material/WorkspacePremiumOutlined';
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Proof from '../shared/Proof';
import toast from 'react-hot-toast';

const License = ({ control, setValue, getValues, setProfileChanged }) => {
  const {
    fields: licenses,
    append: addLicenses,
    remove: removeLicenses
  } = useFieldArray({
    control,
    name: 'license'
  });

  useEffect(() => {
    if (licenses.length === 0) {
      addLicenses({ license: '' });
    }
  }, [licenses, addLicenses]);

  const [licenseProofUrls, setLicenseProofUrls] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedLicenseIndex, setSelectedLicenseIndex] = useState(null);

  const watchedLicenseProofUrls = useWatch({
    control,
    name: licenses.map((_, index) => `license.${index}.proofUrl`)
  });

  useEffect(() => {
    const storedProofUrls = licenses.map((_, index) => getValues(`license.${index}.proofUrl`));
    setLicenseProofUrls(storedProofUrls);
  }, [licenses, getValues, watchedLicenseProofUrls]);

  const handleProofUpload = (file, index) => {
    const timestamp = Date.now();
    setValue(`licenseProof.${index}`, {
      index: index,
      file: file,
      fileName: `${file.name}.${timestamp}`
    });
    setProfileChanged(true);
    // const updatedProofUrls = [...licenseProofUrls];
    // updatedProofUrls[index] = url;
    // setLicenseProofUrls(updatedProofUrls);
  };

  const deleteLicense = async (index) => {
    const licenseProofUrl = licenseProofUrls[index] || getValues(`license.${index}.proofUrl`);
    if (licenseProofUrl) {
      const currentDeleteLicenseProof = (await getValues('deleteLicenseProof')) || [];
      currentDeleteLicenseProof.push(licenseProofUrl);
      await setValue('deleteLicenseProof', currentDeleteLicenseProof);
      const isLicenseProof = await getValues(`licenseProof.${index}`);
      if (isLicenseProof) {
        setValue(`licenseProof.${index}`, null);
      }
      setValue(`license.${index}.proofUrl`, '');
      if (licenseProofUrls?.[index]) {
        licenseProofUrls[index] = '';
      }
    }
    removeLicenses(index);
    toast.success(`License ${index + 1} deleted successfully.`);
    setProfileChanged(true);
  };

  const deleteLicenseProofFile = async (index) => {
    const fileToBeDeletedFromStorage = await getValues(`license.${index}.proofUrl`);
    if (fileToBeDeletedFromStorage) {
      // await setValue(`deleteLicenseProof.${index}`, fileToBeDeletedFromStorage);
      const currentDeleteLicenseProof = (await getValues('deleteLicenseProof')) || [];
      currentDeleteLicenseProof.push(fileToBeDeletedFromStorage);
      await setValue('deleteLicenseProof', currentDeleteLicenseProof);
    }
    const isLicenseProof = await getValues(`licenseProof.${index}`);
    if (isLicenseProof) {
      setValue(`licenseProof.${index}`, null);
    }
    await setValue(`license.${index}.proofUrl`, '');
    if (licenseProofUrls?.[index]) {
      licenseProofUrls[index] = '';
    }
    setProfileChanged(true);
  };

  const handleDeleteClick = (index) => {
    setSelectedLicenseIndex(index);
    setOpenDialog(true);
  };

  const confirmDelete = () => {
    if (selectedLicenseIndex !== null) {
      deleteLicense(selectedLicenseIndex);
      setSelectedLicenseIndex(null);
      setOpenDialog(false);
      setProfileChanged(true);
    }
  };

  const cancelDelete = () => {
    setSelectedLicenseIndex(null);
    setOpenDialog(false);
  };

  return (
    <>
      <div className="heading-div ">
        <b>License</b>
      </div>
      {licenses.map((item, index) => (
        <React.Fragment key={item.id}>
          <div key={item.id} className="form-row flex-container">
            <div className="field flex-container margin-top--md">
              <Controller
                name={`license.${index}.license`}
                control={control}
                defaultValue=""
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    label={`License ${index + 1}`}
                    variant="outlined"
                    size="small"
                    value={value}
                    fullWidth
                    placeholder="RCI A2024..."
                    // onChange={onChange}
                    onChange={(e) => {
                      onChange(e);
                      setProfileChanged(true);
                    }}
                    // required
                    error={!!error}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <WorkspacePremiumOutlinedIcon color="primary" />
                        </InputAdornment>
                      )
                    }}
                    helperText={error ? error.message : ''}
                  />
                )}
                // rules={{ required: "Please fill this field" }}
              />
            </div>
            <IconButton
              sx={{
                marginTop: '18px',
                width: '40px',
                height: '40px'
              }}
              onClick={() => handleDeleteClick(index)}
              color="secondary"
              size="small">
              <DeleteOutlineIcon />
            </IconButton>
          </div>
          <div>
            <Proof
              existingUrl={licenseProofUrls[index] || ''}
              path={`license[${index}]`}
              onUpload={(file) => handleProofUpload(file, index)}
              deleteProofFile={() => deleteLicenseProofFile(index)}
            />
          </div>
        </React.Fragment>
      ))}
      <Button
        sx={{ marginTop: '10px' }}
        variant="text"
        color="primary"
        startIcon={<AddIcon />}
        onClick={() => {
          addLicenses({ license: '' });
          setProfileChanged(true);
        }}>
        Add More
      </Button>
      <Dialog open={openDialog} onClose={cancelDelete}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to delete this license?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelDelete} color="primary">
            Cancel
          </Button>
          <Button onClick={confirmDelete} color="error">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <style jsx>{`
        .heading-div {
          border-bottom: 1px solid var(--border-gray);
          padding: 5px 5px 15px 5px;
          margin-top: 10px;
          font-size: 18px;
        }

        .form-row {
          // justify-content: space-between;
          // flex-wrap: wrap;
          align-items: center;
        }
        .field {
          width: 49%;
          // min-width: 300px;
          position: relative;
        }

        @media only screen and (max-width: 600px) {
          .field {
            width: 100%;
          }
        }
      `}</style>
    </>
  );
};

export default License;
