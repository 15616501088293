import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import RichText from '../../../../shared/components/quill-editor/RichText';
import MeetingDetails from './meeting-details/MeetingDetails';
import UpdateMeetingDetails from './update-meeting-details/UpdateMeetingDetails';
import ActionButtons from './action-buttons/ActionButtons';
import AddFiles from './add-files/AddFiles';
import {
  getDateTime,
  getCurrentDateTime,
  cleanContent,
  saveNotesToDb,
  getNotesData,
  getBookingDetails,
  calculateDurationInMinutes
} from './shared/clientNotes.service';
import LockIcon from '@mui/icons-material/Lock';
import toast from 'react-hot-toast';
import { useStoreState } from 'easy-peasy';
import { auth, database, storage } from '../../../../helper/firebase.helper';
import { collection, doc, getDoc, getDocs, query, where } from 'firebase/firestore';
import Loader from '../../../../shared/components/loader/Loader';
import AddForms from './add-forms/AddForms';
import { tempForms } from './shared/tempNotesData';
import { deleteObject, getDownloadURL, ref, uploadBytes } from 'firebase/storage';

const ClientNotes = () => {
  const { id: clientId, bookingId } = useParams();
  const user = useStoreState((state) => state.user);
  const currentUser = auth.currentUser;
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [filesToDelete, setFilesToDelete] = useState([]);
  const [meetingDetails, setMeetingDetails] = useState({
    locationName: '',
    serviceName: '',
    meetingStart: null,
    meetingEnd: null
  });
  const [updatedMeetingDetails, setUpdatedMeetingDetails] = useState({
    startTime: null,
    duration: 0,
    location: '',
    service: '',
    meetingStart: null,
    meetingEnd: null
  });
  const [signed, setSigned] = useState(false);
  const [show, setShow] = useState(true);
  const [notes, setNotes] = useState({ content: '', files: [] });
  const [clientData, setClientData] = useState();
  const [locations, setLocations] = useState([]);
  const [isLoading, setLoading] = useState(false);
  // const [selectedForms, setSelectedForms] = useState([]);
  const [signingTime, setSigningTime] = useState(null);

  const handleShowNameChange = () => {
    setShow(!show);
  };

  const handlePDFdownload = () => {
    if (show) {
      console.log(`${notes.content} with client name and email`);
    } else {
      console.log(`${notes.content} without client name and email`);
    }
  };

  const handleMeetingDetailsChange = (e) => {
    const { name, value } = e.target;
    if (name === 'startTime') {
      const timestamp = new Date(value).getTime();
      setUpdatedMeetingDetails((prevDetails) => ({
        ...prevDetails,
        [name]: timestamp
      }));
    } else {
      setUpdatedMeetingDetails((prevDetails) => ({
        ...prevDetails,
        [name]: value
      }));
    }
  };

  const handleUnlock = () => {
    setSigned(false);
  };

  const handleSaveDraft = async () => {
    setLoading(true);
    let noteFilesUploaded = [];
    if (filesToUpload && filesToUpload.length) {
      const noteFiles = filesToUpload.map((item, i) => {
        if (item.file && item.fileName) {
          const noteFileRef = ref(
            storage,
            `users/${user.uid}/client_notes/${clientId}/${item.fileName}`
          );
          return uploadBytes(noteFileRef, item.file).then(async () => {
            const notesAttachmentURL = await getDownloadURL(noteFileRef);
            noteFilesUploaded.push({ name: item.file.name, url: notesAttachmentURL });
          });
        }
      });
      await Promise.all(noteFiles);
    }

    if (filesToDelete && filesToDelete.length) {
      const deleteNoteFilesTasks = filesToDelete.map(async (fileUrl) => {
        const fileRef = ref(storage, fileUrl);
        return deleteObject(fileRef);
      });
      await Promise.all(deleteNoteFilesTasks);
    }

    try {
      await toast.promise(
        saveNotesToDb(bookingId, {
          updatedMeetingDetails: updatedMeetingDetails,
          notes: {
            content: notes.content,
            files: [...noteFilesUploaded, ...notes.files]
          },
          // selectedForms: selectedForms,
          signed: false,
          clientId: clientId,
          practitionerId: user.uid
        }),
        {
          loading: 'Saving...',
          success: 'Saved!',
          error: `Couldn't save`
        }
      );
      const updatedData = await getNotesData(bookingId);
      if (updatedData) {
        setNotes(updatedData.notes);
        setUpdatedMeetingDetails(updatedData.updatedMeetingDetails);
        // setSelectedForms(updatedData.selectedForms);
        setSigned(updatedData.signed);
        setSigningTime(updatedData.signingTime);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error('Error saving data:', error);
    }
    setFilesToUpload([]);
    setFilesToDelete([]);
  };

  const handleSignClick = async () => {
    setLoading(true);
    setSigned(true);
    const noteFilesUploaded = [];
    if (filesToUpload && filesToUpload.length) {
      const noteFiles = filesToUpload.map((item, i) => {
        if (item.file && item.fileName) {
          const noteFileRef = ref(
            storage,
            `users/${user.uid}/client_notes/${clientId}/${item.fileName}`
          );
          return uploadBytes(noteFileRef, item.file).then(async () => {
            const notesAttachmentURL = await getDownloadURL(noteFileRef);
            noteFilesUploaded.push({ name: item.file.name, url: notesAttachmentURL });
          });
        }
      });
      await Promise.all(noteFiles);
    }
    if (filesToDelete && filesToDelete.length) {
      const deleteNoteFilesTasks = filesToDelete.map(async (fileUrl) => {
        const fileRef = ref(storage, fileUrl);
        return deleteObject(fileRef);
      });
      await Promise.all(deleteNoteFilesTasks);
    }
    try {
      await toast.promise(
        saveNotesToDb(bookingId, {
          updatedMeetingDetails: updatedMeetingDetails,
          notes: {
            content: notes.content,
            files: [...noteFilesUploaded, ...notes.files]
          },
          // selectedForms: selectedForms,
          signed: true,
          signingTime: Date.now(),
          clientId: clientId,
          practitionerId: user.uid
        }),
        {
          loading: 'Saving...',
          success: 'Saved!',
          error: `Couldn't save`
        }
      );
      const updatedData = await getNotesData(bookingId);
      if (updatedData) {
        setNotes(updatedData.notes);
        setUpdatedMeetingDetails(updatedData.updatedMeetingDetails);
        // setSelectedForms(updatedData.selectedForms);
        setSigned(updatedData.signed);
        setSigningTime(updatedData.signingTime);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error('Error saving data:', error);
    }
    setFilesToUpload([]);
    setFilesToDelete([]);
  };

  useEffect(() => {
    setLoading(true);
    getDoc(doc(database, 'clients', clientId))
      .then((res) => {
        setClientData({ id: res.id, ...res.data() });
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(`failed to get client profile: ${err.message}`);
      });
  }, [clientId]);

  const fetchLocations = async () => {
    setLoading(true);
    const data = await getDocs(
      query(collection(database, 'practitioners', user?.uid, 'locations')),
      where('active', '==', true)
    ).then((docs) => {
      const res = [];
      docs.forEach((doc) => res.push(doc.data()));
      setLoading(false);
      return res;
    });
    if (data && data.length) {
      setLocations(data);
      setLoading(false);
    }
    setLoading(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const booking = await getBookingDetails(bookingId);
        if (booking) {
          setMeetingDetails({
            serviceName: booking.serviceName,
            locationName: booking.locationName,
            meetingStart: booking.meetingStart,
            meetingEnd: booking.meetingEnd,
            orgSiteDomain: booking.orgSiteDomain
          });
          setUpdatedMeetingDetails({
            service: booking.service,
            location: booking.location,
            meetingStart: booking.meetingStart,
            meetingEnd: booking.meetingEnd,
            duration: calculateDurationInMinutes(booking.meetingStart, booking.meetingEnd),
            startTime: booking.meetingStart
          });

          fetchLocations();

          try {
            const data = await getNotesData(bookingId);
            if (data) {
              setSigned(data.signed);
              setNotes(data.notes);
              setUpdatedMeetingDetails(data.updatedMeetingDetails);
              setSigningTime(data.signingTime);
              // setSelectedForms(data.selectedForms);
            }
          } catch (error) {
            if (error.code === 'permission-denied') {
              console.log('First time user does not have permission to access notes.');
            } else {
              console.error('Error fetching notes:', error.message);
              toast.error('Error fetching notes data:', error.message);
            }
          }

          setLoading(false);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error.message);
        toast.error('Error fetching notes data:', error.message);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <div className="notes-wrapper margin-top--sm">
        {isLoading ? <Loader /> : null}

        <MeetingDetails
          id={clientId}
          show={show}
          handleShowNameChange={handleShowNameChange}
          clientData={clientData}
          meetingDetails={meetingDetails}
        />
        <div className="margin-top--lg quill-container">
          <UpdateMeetingDetails
            updatedMeetingDetails={updatedMeetingDetails}
            handleMeetingDetailsChange={handleMeetingDetailsChange}
            signed={signed}
            locations={locations}
          />

          {cleanContent(notes.content) && signed ? (
            <div
              className="notes-content-div margin-top--md"
              style={{ minHeight: '50px' }}
              dangerouslySetInnerHTML={{ __html: notes.content }}></div>
          ) : !signed ? (
            <RichText
              value={notes.content}
              setValue={(content) => setNotes((prevNotes) => ({ ...prevNotes, content }))}
              placeholder="Write your notes here..."
              disabled={signed}
            />
          ) : null}

          <AddFiles
            filesToUpload={filesToUpload}
            setFilesToUpload={setFilesToUpload}
            filesToDelete={filesToDelete}
            setFilesToDelete={setFilesToDelete}
            signed={signed}
            id={clientId}
            notes={notes}
            setNotes={setNotes}
          />
          {/* <AddForms
            signed={signed}
            tempForms={tempForms}
            selectedForms={selectedForms}
            setSelectedForms={setSelectedForms}
          /> */}
        </div>
        {signed && (
          <div className="signature-container" style={{ padding: '20px 20px 5px' }}>
            <p className="sacramento-regular"> {currentUser?.displayName}</p>
            <p>
              <LockIcon color="primary" style={{ marginBottom: '-5px' }} /> Signed by&nbsp;
              {currentUser?.displayName}&nbsp;({user?.email}) on {getDateTime(signingTime)}
            </p>
          </div>
        )}
      </div>
      <ActionButtons
        signed={signed}
        handlePDFdownload={handlePDFdownload}
        handleSignClick={handleSignClick}
        handleSaveDraft={handleSaveDraft}
        handleUnlock={handleUnlock}
      />
      <style jsx>
        {`
          .notes-wrapper {
            padding: 10px 10px;
            background-color: #f2f2f2;
            width: 90%;
            border-radius: 5px;
          }
          .quill-container {
            // max-width: 900px;
            background-color: white;
            padding: 20px 10px;
            border-radius: 5px;
          }
          .notes-content-div {
            border: 0.5px solid var(--gray-lightest);
            padding: 10px 20px 20px;
          }
          .sacramento-regular {
            font-family: 'Sacramento', cursive;
            font-weight: 400;
            font-style: normal;
            font-size: 25px;
            margin-left: 10px;
            letter-spacing: -3px;
          }

          @media only screen and (max-width: 800px) {
            .notes-wrapper {
              width: 94%;
            }
          }
        `}
      </style>
    </>
  );
};

export default ClientNotes;
