import { Button, Tooltip } from '@mui/material';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import AutorenewOutlinedIcon from '@mui/icons-material/AutorenewOutlined';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import InfoIcon from '@mui/icons-material/Info';
import React from 'react';

const ActionButtons = ({
  signed,
  handlePDFdownload,
  handleSignClick,
  handleSaveDraft,
  handleUnlock
}) => {
  return (
    <>
      <div className="action-buttons-container flex-container justify-between margin-top--sm margin-bottom--lg">
        {/* <Button
          disabled={!signed}
          startIcon={<FileDownloadOutlinedIcon />}
          size="small"
          style={{
            width: '130px',
            maxHeight: '40px',
            visibility: signed ? 'visible' : 'hidden'
          }}
          onClick={handlePDFdownload}>
          Download PDF
        </Button> */}
        <div style={{ textAlign: 'right', marginLeft: 'auto' }}>
          {!signed ? (
            <>
              <Button
                variant="outlined"
                size="small"
                style={{ marginTop: '10px' }}
                startIcon={<AutorenewOutlinedIcon color="secondary" />}
                onClick={handleSaveDraft}>
                Save draft
              </Button>

              <Button
                variant="contained"
                size="small"
                startIcon={<LockIcon />}
                onClick={handleSignClick}
                style={{ marginTop: '10px', marginLeft: '10px' }}>
                Sign
              </Button>
            </>
          ) : (
            <>
              <Button
                variant="contained"
                size="small"
                startIcon={<LockOpenIcon />}
                onClick={handleUnlock}
                style={{
                  marginTop: '10px'
                  // , marginRight: '10px'
                }}>
                Unlock
              </Button>
              {/* <Tooltip
                title="Text needs to be added here"
                aria-label="edit"
                components={{ Tooltip: 'div' }}
                arrow>
                <InfoIcon color="secondary" fontSize="small" style={{ marginBottom: '-5px' }} />
              </Tooltip> */}
            </>
          )}
        </div>
      </div>
      <style jsx>
        {`
          .action-buttons-container {
            width: 90%;
            padding: 0px 10px;
          }
             @media only screen and (max-width: 800px) {
           .action-buttons-container {
             width: 94%;
          }
        `}
      </style>
    </>
  );
};

export default ActionButtons;
