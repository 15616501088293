import { formatRelative } from 'date-fns';
import React from 'react';

const InviteStatus = ({ statusColor, inviteItem, statusLabel }) => {
  return (
    <>
      <p className="item text--sm" style={{ wordBreak: 'break-word' }}>
        <span style={{ color: statusColor[inviteItem.status] }}>
          {statusLabel[inviteItem.status]}
        </span>{' '}
        {formatRelative(inviteItem.updateTime || new Date(), new Date())}
      </p>
      <style jsx>{`
        .item {
          flex: 1;
          text-overflow: ellipsis;
          height: fit-content;
        }
      `}</style>
    </>
  );
};

export default InviteStatus;
