import { Avatar } from '@mui/material';
import React from 'react';

const PractitionerEmail = ({ statusColor, inviteItem, practitioner }) => {
  return (
    <>
      <div
        className="item text--sm flex-container align-center"
        style={{ wordBreak: 'break-word' }}>
        <Avatar
          style={{
            width: '30px',
            height: '30px',
            fontSize: '16px',
            marginRight: '5px',
            backgroundColor: statusColor[inviteItem.status]
          }}>
          {practitioner.uid === inviteItem.practitioner
            ? inviteItem['email']?.toUpperCase()[0]
            : practitioner.email === inviteItem.email
              ? inviteItem['practitionerEmail']?.toUpperCase()[0]
              : null}
        </Avatar>
        {practitioner.uid === inviteItem.practitioner
          ? inviteItem['email']
          : practitioner.email === inviteItem.email
            ? inviteItem['practitionerEmail']
            : null}
      </div>
      <style jsx>{`
        .item {
          flex: 1;
          text-overflow: ellipsis;
          height: fit-content;
        }
      `}</style>
    </>
  );
};

export default PractitionerEmail;
