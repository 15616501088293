import React, { useEffect, useState } from 'react';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { Controller } from 'react-hook-form';
import { FileCopyOutlined } from '@mui/icons-material';
import toast from 'react-hot-toast';
import { getDomainFromDb } from '../../../my-practice/domain-branding/domain/domain.service';
import { useStoreState } from 'easy-peasy';

const ProfileLink = ({ control, verified, setProfileChanged }) => {
  const [domainData, setDomainData] = useState();

  const practitionerData = useStoreState((state) => state.practitionerData);

  useEffect(() => {
    getDomainFromDb(practitionerData.own_organisation).then((data) => {
      setDomainData(data);
    });
  }, []);

  const copyProfileLink = (profileLink) => {
    navigator.clipboard.writeText(
      `https://${domainData.siteDomain}.detalks.com/therapists/${profileLink}`
    );
    toast.success('copied');
  };
  return (
    <>
      <div className="form-row">
        <Controller
          name="profileLink"
          control={control}
          defaultValue=""
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <div className="flex-container margin-top--xs margin-bottom--xs">
              <div className=" field">
                <TextField
                  label="Profile Link"
                  variant="outlined"
                  size="small"
                  value={value}
                  required
                  disabled
                  fullWidth
                  // onChange={onChange}
                  onChange={(e) => {
                    onChange(e);
                    setProfileChanged(true);
                  }}
                  error={!!error}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <span
                          style={{
                            fontWeight: 'bold',
                            fontSize: '20px',
                            color: '#0884ff',
                            marginLeft: '7px',
                            paddingRight: '11px'
                          }}>
                          /
                        </span>
                      </InputAdornment>
                    )
                  }}
                />
              </div>
              {domainData?.siteDomain && (
                <div className="copy-icon margin-top--xxs">
                  <IconButton
                    sx={{
                      marginTop: '-5px',
                      width: '40px',
                      height: '40px'
                    }}
                    onClick={() => copyProfileLink(value)}>
                    <FileCopyOutlined color="primary" />
                  </IconButton>
                </div>
              )}
            </div>
          )}
          rules={{ required: 'Please fill this field' }}
        />
        <p className="field text--xs helper-text gray-text" style={{ marginTop: '-5px' }}>
          Your Profile Link must contain 3-100 letters or numbers. Please avoid using spaces,
          symbols, or special characters.
        </p>
      </div>
      <style jsx>{`
        .form-row {
          justify-content: space-between;
          flex-wrap: wrap;
        }
        .field {
          width: 49%;
          min-width: 300px;
          position: relative;
        }
        .helper-text {
          text-align: left;
        }
        @media only screen and (max-width: 600px) {
          .field {
            width: 100%;
          }
        }
      `}</style>
    </>
  );
};

export default ProfileLink;
