import React, { useEffect, useState } from 'react';
import InviteHistoryItem from './invite-history-item/InviteHistoryItem';
import { Hidden } from '@mui/material';
import { useStoreState } from 'easy-peasy';
import { collection, getDocs, onSnapshot, orderBy, query, where } from 'firebase/firestore';
import { database } from '../../../../helper/firebase.helper';
import Loader from '../../../../shared/components/loader/Loader';

const InviteHistory = () => {
  const [inviteHistory, setInviteHistory] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const practitioner = useStoreState((state) => state.user);

  useEffect(() => {
    setLoading(true);
    const unsubscribe = onSnapshot(
      query(
        collection(database, 'invites'),
        where('practitioner', '==', practitioner.uid),
        orderBy('updateTime', 'desc')
      ),
      (docs) => {
        const res = [];
        docs.forEach((doc) => res.push({ id: doc.id, ...doc.data() }));
        setLoading(false);
        setInviteHistory(res);
        setLoading(false);
        return res;
      }
    );
    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <div className="wrapper margin-top--sm">
      {isLoading ? <Loader /> : null}

      <div className="flex-container header">
        <p className="item text--sm gray-text">Practitioner Email</p>
        <Hidden smDown>
          <p className="item text--sm gray-text">Practitioner Name</p>
        </Hidden>
        <Hidden smDown>
          <p className="item text--sm gray-text">Status</p>
        </Hidden>
        <div className="item text--sm gray-text flex-container actions">Actions</div>
      </div>
      <hr />
      <div>
        {inviteHistory.map((inviteItem) => (
          <InviteHistoryItem
            key={inviteItem.id}
            inviteItem={inviteItem}
            practitioner={practitioner}
            // isInGroupPractice={!isAdmin}
            // seatsAvailable={currPackage?.seats > currPackage?.group_seats_used}
            // usedSeats={currPackage?.group_seats_used}
          />
        ))}
      </div>
      <style jsx>{`
        .header {
        }
        .item {
          width: 25%;
          flex-grow: 1;
        }
        hr {
          border: none;
          background-color: var(--gray-lightest);
          height: 1px;
        }
        .actions {
          justify-content: space-between;
          align-items: center;
        }
      `}</style>
    </div>
  );
};

export default InviteHistory;
